import React, { ChangeEvent, useEffect, useState } from "react"
import Layout from '../components/Layout';
import { authenticate } from '../utils/auth';
import { parse as queryParse } from 'query-string';
import { navigate } from 'gatsby';
import styles from './login.module.scss';

const LoginPage = ({ location }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState('/');
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if(authenticated) {
      navigate(decodeURI(redirectUrl));
    }

    return undefined;
  }, [authenticated]);

  useEffect(() => {
    const redirectParam = queryParse(location.search).r as string || '/';
    setRedirectUrl(decodeURI(redirectParam));

    return undefined;
  });

  const handleUsernameChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setUsername(evt.target.value);
  }

  const handlePasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setErrors([]);

    if(!authenticate(username, password)) {
      setErrors(['Login failed, try again']);
    } else {
      setAuthenticated(true);
    }
  }

  return (
    <Layout>
      <div className={styles.formContainer}>
        <h1>Member Login</h1>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label htmlFor="username" className={styles.formLabel}>
            Username:
          </label>
          <input className={styles.formInput} required id="username" name="username" type="text" onChange={handleUsernameChange} value={username}  />
          <label htmlFor="password" className={styles.formLabel}>
            Password:
          </label>
          <input className={styles.formInput} required id="password" name="password" type="password" onChange={handlePasswordChange} value={password}  />
          {errors.length > 0 && <ul className={styles.errorList}>
            {errors.map(err=>
              (<li key={err}>{err} </li>)
            )}
          </ul>}
          <button className={styles.formBtn}>LOGIN</button>
        </form>
      </div>
    </Layout>
  )
}

export default LoginPage;
